<template>
  <div id="app" data-app>
    <v-toolbar dark color="primary darken-1">
      <span v-if="appLogoSecondary !== ''" style="min-width: 250px" class="text-center text-center">
        <img :src="appLogoSecondary" class="logo" />
      </span>
      <v-spacer />
      <v-toolbar-items class="hidden-sm-and-down">
        <v-spacer />
        <v-btn depressed color="primary darken-1" @click="goHome">{{ $t('registro.init_session') }}</v-btn>
      </v-toolbar-items>
      <v-icon class="mobile-icon pointer" color="white" @click="goHome">mdi-account-circle</v-icon>
    </v-toolbar>
    <v-layout>
      <v-flex v-if="!savedOk" xs12 sm10 md8 lg8 xl6 offset-sm1 offset-md2 offset-lg2 offset-xl3 class="mt-4">
        <v-form ref="form" v-model="isFormValid">
          <v-card color="white" outlined>
            <v-container fill-height fluid>
              <v-layout fill-height text>
                <v-flex xs12 align-end flexbox>
                  <p class="headline">{{ $t('registro.professional_registration_process') }}</p>

                  <div class="mt-2">
                    <p class="subheading">
                      {{ $t('registro.have_an_account') }}
                      <span class="blue--text pointer" @click="goHome">{{ $t('registro.init_session') }}</span>
                    </p>
                  </div>

                  <v-progress-linear v-if="loading" indeterminate color="blue" />

                  <v-stepper v-model="e6" vertical>
                    <v-stepper-step :complete="e6 > 1" step="1">
                      <span class="subheading">{{ $t('registro.personal_info') }}</span>
                    </v-stepper-step>
                    <v-stepper-content step="1">
                      <p class="grey--text">{{ $t('registro.please_complete_info') }}</p>
                      <v-layout wrap class="container">
                        <v-flex xs12 sm6>
                          <v-text-field
                            v-model="usuario.nombre"
                            class="mr-2"
                            :label="$t('patient.first_name')"
                            type="text"
                            required
                            :rules="rules.required"
                          />
                        </v-flex>
                        <v-flex xs12 sm6>
                          <v-text-field
                            v-model="usuario.apellidos"
                            class="mr-2"
                            :label="$t('patient.last_name')"
                            type="text"
                            required
                            :rules="rules.required"
                          />
                        </v-flex>
                        <v-flex xs12 sm6>
                          <!-- <v-subheader class="subheader">{{ $t('patient.phone') }}</v-subheader> -->
                          <!-- <vue-tel-input v-model="usuario.phone" @input="changePhone" v-bind="bindProps"></vue-tel-input>-->
                          <v-text-field
                            v-model="usuario.phone"
                            class="mr-2"
                            :label="$t('patient.phone')"
                            type="mail"
                            :rules="rules.phoneNumber"
                          />
                        </v-flex>
                        <v-flex xs12 sm6>
                          <v-text-field
                            v-model="usuario.email"
                            class="mr-2"
                            :label="$t('message.email')"
                            type="mail"
                            required
                            :rules="rules.email"
                          />
                        </v-flex>
                        <v-flex xs12 sm6>
                          <v-text-field
                            v-model="usuario.dni"
                            class="mr-2"
                            label="DNI o NIE"
                            type="text"
                            required
                            :rules="rules.required"
                          />
                        </v-flex>
                        <v-flex xs12 sm6>
                          <v-text-field
                            v-model="usuario.colegiado"
                            class="mr-2"
                            :label="$t('user.num_colegiado')"
                            type="text"
                            required
                            :rules="rules.collegiateNumber"
                          />
                        </v-flex>
                        <v-flex xs12>
                          <div slot="widget-content">
                            <v-select
                              v-if="!loadingSpecialties"
                              ref="select"
                              v-model="usuario.especialidades"
                              :label="$t('user.especialidades')"
                              :items="specialties"
                              chips
                              item-text="name_es"
                              item-value="id"
                              item-valor="id"
                              multiple
                              @change="closeAfterSelect"
                            >
                              <template slot="selection" slot-scope="data">
                                <v-chip
                                  :input-value="data.selected"
                                  close
                                  class="chip--select-multi"
                                  color="blue lighten-4"
                                  @click:close="removeEspecialidad(data.item.id)"
                                >
                                  {{ data.item.name_es }}
                                </v-chip>
                              </template>
                              <template slot="item" slot-scope="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content v-text="data.item" />
                                </template>
                                <template v-else>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ data.item.name_es }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-select>
                          </div>
                        </v-flex>

                        <!--                        <v-flex xs12>-->
                        <!--                          <v-select-->
                        <!--                            :label="$t('user.tipo_prof')"-->
                        <!--                            :items="types" v-model="usuario.tipo_doctor"-->
                        <!--                            single-line-->
                        <!--                            auto required item-text="text" item-value="id" class="selectorTabla"-->
                        <!--                          ></v-select>-->
                        <!--                        </v-flex>-->
                      </v-layout>
                      <v-btn color="primary" :disabled="loading" @click.native="validateDataStep1">{{
                        $t('tour.next')
                      }}</v-btn>
                    </v-stepper-content>
                    <v-stepper-step :complete="e6 > 2" step="2">
                      <span class="subheading">{{ $t('registro.documents') }}</span>
                    </v-stepper-step>

                    <v-stepper-content step="2">
                      <p v-if="isPassport" class="grey--text">{{ $t('registro.add_passport') }}</p>
                      <p v-if="isNie" class="grey--text">{{ $t('registro.add_nie') }}</p>
                      <p v-else class="grey--text">{{ $t('registro.add_dni') }}</p>
                      <v-layout wrap class="container">
                        <v-flex xs12 md8 offset-md2>
                          <span class="dni-title">{{ $t('registro.front_image') }}</span>
                          <file-pond
                            ref="pond"
                            name="test"
                            :label-idle="$t('registro.drag_or_select_file')"
                            :allow-multiple="false"
                            accepted-file-types="image/jpeg, image/png "
                            max-file-size="70KB"
                            :files="myFiles"
                            @addfile="addedDniFront"
                            @removefile="removeDniFront"
                          />
                        </v-flex>
                        <v-flex v-if="!isPassport" xs12 md8 offset-md2>
                          <span class="dni-title">{{ $t('registro.rear_image') }}</span
                          ><br />
                          <file-pond
                            ref="pond2"
                            name="test"
                            :label-idle="$t('registro.drag_or_select_file')"
                            :allow-multiple="false"
                            accepted-file-types="image/jpeg, image/png"
                            :files="myFiles"
                            @addfile="addedDniBack"
                            @removefile="removeDniBack"
                          />
                        </v-flex>
                      </v-layout>

                      <v-layout align-center class="mt-2 mb-2 ml">
                        <v-flex xs1 sm1 lg1 text-right>
                          <v-checkbox v-model="acceptConditions" />
                        </v-flex>
                        <v-flex xs11 sm11 lg11>
                          <span
                            v-html="
                              $t('registro.accept_conditions', {
                                privacyPolicyLink: 'https://docline.es/politica-privacidad/',
                              })
                            "
                          />
                        </v-flex>
                      </v-layout>
                      <v-btn :disabled="loading" color="primary" @click.native="saveData">{{
                        $t('common.save')
                      }}</v-btn>
                      <v-btn text @click.native="e6 = 1">{{ $t('common.cancel') }}</v-btn>
                    </v-stepper-content>
                  </v-stepper>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-form>
      </v-flex>

      <v-flex v-else xs12 sm8 md8 lg8 xl6 offset-sm2 offset-md2 offset-lg2 offset-xl3 class="mt-4">
        <v-card color="white" outlined>
          <v-container fill-height fluid class="saved-ok">
            <v-layout fill-height text>
              <v-flex xs12 align-end flexbox class="mt-4">
                <v-layout wrap>
                  <v-flex xs12 class="mt-4 centered">
                    <check-mark animate size="60" class="checkmark" />
                  </v-flex>
                  <v-flex xs8 offset-xs2>
                    <div class="display-1 grey--text text--darken-1 centered mt-3">
                      {{ $t('registro.save_ok_title') }}
                    </div>
                  </v-flex>
                  <v-flex xs8 offset-xs2 class="text-ok mt-4 mb-4">
                    <div class="subheading grey--text text--darken-1">
                      {{ $t('registro.save_ok_text') }}
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { getAlowPrescribeSpecialties, registerErecipeProf, getHeader } from '@/config/config';
import { Validator } from '@/utils';
import CheckMark from './messages/CheckMark.vue';

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
  name: 'Register',
  components: {
    FilePond,
    CheckMark,
  },
  data() {
    const validator = new Validator();
    return {
      loading: false,
      loadingSpecialties: false,
      e6: 1,
      specialties: [],
      usuario: {
        valid: true,
        id: 0,
        nombre: '',
        apellidos: '',
        email: '',
        nombreusuario: '',
        colegiado: '',
        dni: '',
        tipo_doctor: 1,
        phone: '',
        idioma: 'es',
        especialidades: [],
        password: '',
        password_repeat: '',
      },
      types: [
        { id: 1, text: 'Médico' },
        { id: 2, text: 'Podólogo' },
        { id: 3, text: 'Dentista' },
      ],
      alert: {
        text: '',
        icon: 'mdi-alert-circle',
        color: 'error',
      },
      maxFileSize: 2000000,
      acceptedImageType: ['image/jpeg', 'image/png'],
      myFiles: [],
      fileDniFront: [],
      fileDniBack: [],
      isPassport: false,
      isNie: false,
      savedOk: false,
      bindProps: {
        mode: 'international',
        defaultCountry: 'ES',
        preferredCountries: ['ES', 'FR', 'PT'],
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: 'Introduzca el número de teléfono del paciente',
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 17,
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      isFormValid: false,
      rules: {
        required: [v => !!v || this.$t('registro.alert_input_required')],
        email: [
          validator.rules.required(this.$t('registro.alert_email_required')),
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('registro.alert_email_is_invalid'),
        ],
        phoneNumber: [
          v => !!v || this.$t('registro.alert_phone_required'),
          v => /^[9|6|7]{1}([\d]{2}[-]*){3}[\d]{2}$/.test(v) || this.$t('registro.alert_phone_is_invalid'),
        ],
        collegiateNumber: [
          v => !!v || this.$t('registro.alert_number_required'),
          v => /^\d{9}$/.test(v) || this.$t('registro.alert_number_is_invalid'),
        ],
      },
      acceptConditions: false,
    };
  },
  computed: {
    ...mapState({
      appName: state => state.app.name,
      permissions: state => state.app.permissions,
      appLogoSecondary: state => state.app.images.logoSecondary,
    }),
  },
  mounted() {
    if (
      typeof this.permissions.haveElectronicPrescription === 'undefined' ||
      !this.permissions.haveElectronicPrescription
    ) {
      // this.goHome();
    }

    this.getSpecialties();
    this.hideIntercom();
    this.redirect();
  },

  methods: {
    ...mapActions('layout', ['showAlert']),

    goHome() {
      this.$router.push('/login');
    },

    redirect() {
      if (this.appName !== 'docline') {
        this.goHome();
      }
    },

    getSpecialties() {
      this.loadingSpecialties = true;
      this.$http.get(getAlowPrescribeSpecialties, { headers: getHeader() }).then(response => {
        if (response.status === 200 && response.data) {
          this.specialties = response.data;
          this.loadingSpecialties = false;
          this.loading = false;
        }
      });
    },

    validateDataStep1() {
      this.alert.text = '';
      const emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      // const passportRegex = /^[a-z]{3}[0-9]{6}[a-z]?$/i;
      const nieRegex = /^[XYZ]\d{7,8}[A-Z]$/;
      const collegiateRegex = /^\d{9}$/;
      const phoneRegex = /^[9|6|7]{1}([\d]{2}[-]*){3}[\d]{2}$/;
      // this.isPassport = passportRegex.test(this.usuario.dni);
      this.isNie = nieRegex.test(this.usuario.dni);

      const isValidPhone = phoneRegex.test(this.usuario.phone);
      const phoneRegexWithPrefix = /^[+]?[(]?[0-9]{3}[)]?[-\s\]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
      const isValidPhoneWithPrefix = phoneRegexWithPrefix.test(this.usuario.phone);

      if (this.usuario.nombre.length < 3 || this.usuario.apellidos.length < 3) {
        this.alert.text = this.$t('patient.full_name_required');
      } else if (!isValidPhone && !isValidPhoneWithPrefix) {
        this.alert.text = this.$t('errors.value_required', { value: this.$t('patient.phone') });
      } else if (this.usuario.email === '') {
        this.alert.text = this.$t('errors.email_required');
      } else if (!emailRegex.test(this.usuario.email)) {
        this.alert.text = this.$t('errors.email_invalid');
      } else if (!this.isNie && !this.isDniValid(this.usuario.dni)) {
        if (!this.isDniValid(this.usuario.dni)) {
          if (this.usuario.dni.length === 0) {
            this.alert.text = this.$t('patient.dni_invalid');
          } else {
            this.alert.text = this.$t('patient.dni_invalid_letra');
          }
        } else {
          this.alert.text = this.$t('patient.dni_invalid');
        }
      } else if (!collegiateRegex.test(this.usuario.colegiado)) {
        this.alert.text = this.$t('errors.incorrect_colegiado');
      } else if (this.usuario.especialidades.length < 1) {
        this.alert.text = this.$t('reserva.titulo_especialidad');
      } else if (this.usuario.tipo_doctor === '') {
        this.alert.text = this.$t('errors.type_prof');
      }

      const isValidStep1 = this.alert.text !== '' ? false : true;
      if (isValidStep1) {
        this.e6 = 2;
      } else {
        this.showAlertMsg(this.alert.color, this.alert.icon, this.alert.text);
      }

      return isValidStep1;
    },
    removeEspecialidad(item) {
      this.usuario.especialidades.splice(this.usuario.especialidades.indexOf(item), 1);
      this.usuario.especialidades = [...this.usuario.especialidades];
    },

    closeAfterSelect() {
      this.$refs.select.isMenuActive = false;
    },

    hideIntercom() {
      setTimeout(function() {
        const intercom = document.getElementsByClassName('intercom-launcher')[0];
        if (typeof intercom !== 'undefined' && intercom !== null) {
          intercom.style.display = 'none';
        }
      }, 1000);
    },

    showAlertMsg(color, icon, msg) {
      this.showAlert({
        color: color,
        icon: icon,
        message: msg,
      });
    },

    validateDataStep2() {
      this.alert.text = '';

      if (typeof this.fileDniFront.length !== 'undefined' && this.fileDniFront.length === 0) {
        this.alert.text = this.$t('registro.alert_front_image');
      } else if (!this.isPassport && typeof this.fileDniBack.length !== 'undefined' && this.fileDniBack.length === 0) {
        this.alert.text = this.$t('registro.alert_rear_image');
      } else if (!this.acceptConditions) {
        this.alert.text = this.$t('registro.alert_accept_conditions');
      }

      const isValidStep2 = this.alert.text !== '' ? false : true;
      if (isValidStep2) {
        // this.e6 = 3;
      } else {
        this.showAlertMsg(this.alert.color, this.alert.icon, this.alert.text);
      }

      return isValidStep2;
    },

    addedDniFront: function(error, file) {
      const isValid = this.isFileValid(file);
      if (isValid) {
        this.fileDniFront = file.file;
      } else {
        setTimeout(() => {
          this.$refs.pond.removeFile();
        }, 500);
      }
    },

    addedDniBack: function(error, file) {
      const isValid = this.isFileValid(file);
      if (isValid) {
        this.fileDniBack = file.file;
      } else {
        setTimeout(() => {
          this.$refs.pond2.removeFile();
        }, 500);
      }
    },

    saveData() {
      const isValid = this.validateDataStep2();

      if (isValid) {
        const phone_without_prefix = this.usuario.phone;
        if (this.usuario.phone.trim().length === 9) {
          // TODO: Cuando nos coja esta página el vue, quitar esto y poner el vue-tel-input
          this.usuario.phone = '+34' + this.usuario.phone;
        }

        const formData = new FormData();
        formData.append('fileDniFront', this.fileDniFront);
        if (!this.isPassport) {
          formData.append('fileDniBack', this.fileDniBack);
        }
        formData.append('nombre', this.usuario.nombre);
        formData.append('apellidos', this.usuario.apellidos);
        formData.append('email', this.usuario.email);
        formData.append('colegiado', this.usuario.colegiado);
        formData.append('dni', this.usuario.dni);
        formData.append('tipo_doctor', this.usuario.tipo_doctor);
        formData.append('phone', this.usuario.phone);
        formData.append('especialidades', this.usuario.especialidades);
        formData.append('prefijo', 'dr');

        this.loading = true;

        this.$http
          .post(registerErecipeProf, formData)
          .then(res => {
            if (res.status === 200 && res.data.estado === 1) {
              this.savedOk = true;
            } else {
              this.showAlertMsg('error', '', this.$t('errors.try_again'));
            }
          })
          .catch(err => {
            const textAlert = err.data && err.data.error ? this.$t(err.data.error) : this.$t('errors.try_again');
            this.showAlertMsg('error', '', textAlert);
            this.e6 = 1;
          })
          .finally(() => {
            this.usuario.phone = phone_without_prefix;
            this.loading = false;
          });
      }
    },

    reloadPage() {
      setTimeout(function() {
        location.reload();
      }, 5000);
    },

    removeDniFront: function() {
      this.fileDniFront = [];
    },

    removeDniBack: function() {
      this.fileDniBack = [];
    },

    isFileValid(file) {
      this.alert.text = '';
      if (file.file.size > this.maxFileSize) {
        this.alert.text = this.$t('registro.alert_image_max_size');
      }

      if (!this.acceptedImageType.includes(file.file.type)) {
        this.alert.text = this.$t('registro.alert_image_format');
      }

      const isValidImage = this.alert.text !== '' ? false : true;
      if (!isValidImage) {
        this.showAlertMsg(this.alert.color, this.alert.icon, this.alert.text);
      }

      return isValidImage;
    },

    isDniValid(dni) {
      let numero;
      let letr;
      let letra;
      const expresion_regular_dni = /^\d{8}[a-zA-Z]$/;

      if (expresion_regular_dni.test(dni) === true) {
        numero = dni.substr(0, dni.length - 1);
        letr = dni.substr(dni.length - 1, 1);
        numero = numero % 23;
        letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra.substring(numero, numero + 1);
        if (letra !== letr.toUpperCase()) {
          return false;
        } else {
          return true;
        }
      }

      return false;
    },
  },
};
</script>
<style>
body,
html {
  font-size: 14px;
  color: #4a4a4a;
  background-color: #f2f5f7;
}

.v-stepper,
.v-card {
  box-shadow: none;
}

.centered {
  text-align: center;
}

.v-stepper__step__step {
  background: rgba(0, 0, 0, 0.38);
  color: #ffffff;
}

.primary {
  background-color: #1867c0;
  border-color: #1867c0;
  color: #ffffff;
}

.dni-title {
  font-size: 1.1em;
}
/*
    span.primary.primary > [aria-hidden="true"] {
      background: green;
      background-color: green;
    }

    .v-stepper__step__step > [aria-hidden="true"] {
      background: green !important;
      background-color: green !important;
      border-color: green !important;
      border: 1px solid red;
    }

    span.primary:has(i[aria-hidden="true"]) {
      background: green !important;
      background-color: green !important;
      border-color: green !important;
    }
    */

.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.theme--light .v-input:not(.v-input--is-disabled) input,
.theme--light .v-input:not(.v-input--is-disabled) textarea {
  color: rgba(0, 0, 0, 0.87);
}

.v-text-field__slot,
.v-select__selections {
  border-bottom: 1px solid #4a4a4a;
}

.v-list {
  background-color: #ffffff;
}

.primary.darken-1 {
  background-color: #0062a6 !important;
  border-color: #0062a6 !important;
}

.logo {
  max-height: 35px;
  margin-left: 12px;
  max-width: 240px;
}

.v-snack__wrapper.success {
  background-color: #4caf50;
  border-color: #4caf50;
}

.v-snack__wrapper.error {
  background-color: #ff5252;
  border-color: #ff5252;
}

.icon-done {
  color: #4caf50;
  font-size: 7em;
}

.text-ok {
  text-align: center;
}

.open {
  color: #3478dc;
  text-decoration-color: #3478dc;
}

.title {
  font-size: 20px !important;
  font-weight: 500;
  line-height: 1 !important;
  letter-spacing: 0.02em !important;
}

@media (min-width: 576px) {
  .saved-ok {
    margin-top: 7em;
  }
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 5;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #8bc34a;
  display: inline-block;
}
.checkmark.animation {
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #8bc34a;
  fill: none;
}
.checkmark.animation .checkmark__circle {
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
}
.checkmark.animation .checkmark__check {
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #8bc34a;
  }
}

.vue-tel-input {
  margin-top: 10px;
  width: 98%;
}

.v-messages__message {
  color: #ff5252;
}

.mobile-icon {
  font-size: 3em;
}

@media (min-width: 960px) {
  .mobile-icon {
    display: none;
  }
}

.v-label error--text {
  color: #ff5252;
}

.v-input--checkbox .v-input__control {
  color: #1867c0 !important;
  caret-color: #1867c0 !important;
}
</style>
